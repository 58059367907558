<template>
  <v-form ref="passwordRecoveryForm" @submit.prevent="sendRecoveryEmail">
    <v-text-field
      solo
      v-model="email"
      :rules="[rules.required, rules.email]"
      :label="$t('common.email')"
      prepend-inner-icon="mdi-account"
    ></v-text-field>
    <v-card-actions class="pa-0">
      <v-btn dark block color="primary" type="submit" :loading="loading">
        {{ $t('auth.sendRecoveryEmail') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import validators from '../../common/validators';
import { SEND_RECOVERY_PASSWORD } from '../../store/auth/types';

export default {
  name: 'password-recovery-form',
  data() {
    return {
      loading: false,
      email: '',
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        email: validators.isEmail(this.$i18n.t('common.invalidEmail'))
      }
    };
  },
  methods: {
    async sendRecoveryEmail() {
      if (!this.$refs.passwordRecoveryForm.validate()) {
        return;
      }

      this.loading = true;

      await this.$store.dispatch(SEND_RECOVERY_PASSWORD, { email: this.email });

      this.loading = false;
      this.$refs.passwordRecoveryForm.reset();

      this.$notify({
        type: 'success',
        text: this.$root.$t('auth.recoveryEmailSent')
      });
    }
  }
};
</script>
