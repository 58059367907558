<template>
  <v-container fluid class="pa-6">
    <v-row align="center" justify="center">
      <v-col md="6">
        <v-card tile>
          <v-container class="pa-6">
            <h4 class="text-h5 text-center mb-3 font-weight-light">
              {{ $t('auth.passwordRecovery') }}
            </h4>
            <div class="pb-3">{{ $t('auth.passwordRecoveryText') }}</div>
            <password-recovery-form></password-recovery-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PasswordRecoveryForm from '../components/auth/PasswordRecoveryForm';

export default {
  name: 'password-recovery',
  components: {
    PasswordRecoveryForm
  }
};
</script>
